import {animated, useSpring} from "react-spring";
import React from "react";
import style from './EmployeeFinishPage.module.scss';
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";

const EmployeeFinishPage: React.FC<{
   goBack: () => void,
   finish: () => void,
   dataLoading: boolean
}> = props => {

   const fadeStyles = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
   });

   const fadeStyles2 = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
      delay: 1000
   });

   const fadeStyles3 = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
      delay: 2000
   });

   return (
      <>
         <div className={style['employee-finish-page']}>
            <animated.div style={fadeStyles}>
               <h3 className="header-3 u-margin-bottom-m">Congrats! You’ve completed onboarding!</h3>
            </animated.div>
            <animated.div style={fadeStyles2} className={"info-text"}>
               <p>Thank you for completing the onboarding process!</p>
               <p className="u-margin-top-m">Click 'Finish' to receive your AI Impact Report.</p>
            </animated.div>
            <animated.div style={fadeStyles3} className={"u-margin-top-xl"}>
               <div className="buttons-wrapper">
                  <button className={`button button-secondary`} onClick={props.goBack}><i className="fa-solid fa-arrow-left-long"></i><span>Back</span></button>
                  <SpinnerButton title={'Finish'} cssClasses={`button button-primary`} onClickCallback={props.finish} dataLoading={props.dataLoading} />
               </div>
            </animated.div>
         </div>
      </>
   );

}

export default EmployeeFinishPage;