import React from "react";
import style from './IntroductionPage.module.scss';
import {animated, useSpring} from "react-spring";

const IntroductionPage: React.FC<{
   changeSection: Function;
}> = props => {

   const fadeStyles = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
   });

   const fadeStyles2 = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
      delay: 1000
   });

   const fadeStyles3 = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
      delay: 2000
   });

   return (
      <>
         <div className={style['introduction-page']}>
            <animated.div style={fadeStyles}>
               <h1 className="header-1 u-margin-bottom-m">Welcome to TeamLift!</h1>
            </animated.div>
            <animated.div style={fadeStyles2}>
               <p>We're excited you're here.</p>
               <p>Let's take a few minutes to get to know each other better.</p>
            </animated.div>
            <animated.div style={fadeStyles3}>
               <button className="button button-primary u-margin-top-m" onClick={() => props.changeSection()}>Start building your TeamLift profile <i className="fa-solid fa-arrow-right"></i></button>
            </animated.div>
         </div>
      </>
   )
}

export default IntroductionPage;