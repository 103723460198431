import React, {useContext, useEffect} from 'react';
import AuthContext from '../../../store/auth-context';
import styles from './HeaderComponent.module.scss';
import axios, {AxiosRequestConfig} from 'axios';
import {Avatar} from "@mui/material";
import NotificationCenter from "./NotificationCenter/NotificationCenter";
import {NotificationContextProvider} from "../../../store/notification-context";
import {ErrorResponseDto} from "../../../types/ErrorData";
import useResponsiveComponent from "../../../ui/ResponsiveComponent/ResponsiveComponent";

const HeaderComponent: React.FC<{ accessToken: string, changeMenu: Function }> = (props) => {
   const authStore = useContext(AuthContext);
   const logoutFunction = authStore.logout;
   const pageTitle: string = authStore.userData.pageTitle;
   const profileImageUrl = authStore.userData.profilePicture;
   const getPictureURL = process.env.REACT_APP_PUBLIC_URL + '/auth/profile-picture';
   const width = useResponsiveComponent();

   useEffect(() => {
      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${props.accessToken}`
      }
      if (!authStore.userData.updatedProfilePicture) {
         axios
            .get(getPictureURL, {headers})
            .then((response$) => {
               authStore.changeProfileImage(response$.data.profilePicture);
            })
            .catch((_error$: ErrorResponseDto) => {

            })
      }
   }, [props.accessToken]);

   return (
      <>
         <header className={styles['main-header']}>
            {
               width.windowWidth > 768 && <h2 className="header-2">{pageTitle}</h2>
            }
            {
               width.windowWidth <= 768 && <img src="/assets/images/global-icons/teamlift-logo-white.svg" alt="Teamlift logo"/>
            }
            {/* Menu section */}
            <div className='header-menu'>
               {
                  width.windowWidth > 768 ?
                     <>
                        {/* Notification center */}
                        <NotificationContextProvider>
                           <NotificationCenter/>
                        </NotificationContextProvider>
                        {/* Header menu */}
                        <div className="dropdown">
                           <button
                              className="btn dropdown-toggle btn-header" id="dropdownUser" data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <div className="header-icon-container">
                                 {profileImageUrl ? <img src={profileImageUrl} alt="User Image"/> :
                                    <Avatar sx={{backgroundColor: authStore.userData.color}}
                                            className={'img'}>{authStore.userData.fullName.slice(0, 1).toUpperCase()}</Avatar>}
                              </div>
                           </button>

                           <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end"
                               aria-labelledby="dropdownUser">
                              <li>
                                 <button className="dropdown-item" type="button" onClick={logoutFunction}>
                                    <i className="fa-solid fa-arrow-right-from-bracket"></i> Logout
                                 </button>
                              </li>
                           </ul>
                        </div>
                     </>
                     : <i className="fa-solid fa-bars" onClick={() => props.changeMenu()}></i>
               }
            </div>
         </header>
      </>
   );
}
export default HeaderComponent;