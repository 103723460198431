import React, {useContext, useEffect, useState} from "react";
import style from './AIImpactScorePage.module.scss';
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import RadialSeparators from "../../../../utils/RadialSeparators";
import ReactSlidingPane from "react-sliding-pane";
import Tooltip from "../../../../services/tooltip.service";
import AuthContext from "../../../../store/auth-context";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import useApiService from "../../../../services/api.service";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import {
   AugmentationReportDataRepresentationDto,
   AugmentationSkillsRepresentationDto,
   QuestionnaireSessionStatusEnum
} from "../../../../types/AugmentationData";
import {capitalizeFirstLetter} from "../../../../utils/capitalize-first-letter";
import useResponsiveComponent from "../../../../ui/ResponsiveComponent/ResponsiveComponent";
import {BreakingPageWidths} from "../../../../types/constants/BreakingWidths";
import {LinearProgress} from "@mui/material";
import GaugeComponent from "react-gauge-component";
import {animated, useSpring, useTransition} from "react-spring";
import {SkillDto} from "../../../../types/EmployeeDashboard";

export enum ImpactScore {
   NOT_AUGMENTED_LIKED = 1,
   AUGMENTED_DISLIKED = 2,
   NOT_AUGMENTED_DISLIKED = 3,
   AUGMENTED_LIKED = 4,
}

const AIImpactScorePage: React.FC = () => {

   const [selectedSection, setSelectedSection] = useState<ImpactScore>();
   const [showScoreResults, setShowScoreResults] = useState(false);
   const [rotation, setRotation] = useState({outer: 1, inner: 1});
   const [openPanel, setOpenPanel] = useState(true);
   const [augmentationData, setAugmentationData] = useState<AugmentationReportDataRepresentationDto>();
   const [showTeamoForm, setShowTeamoForm] = useState(false);
   const [activeSection, setActiveSection] = useState(0);
   const pageWidth = useResponsiveComponent();

   const fadeStyles = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration: 1000},
      delay: 750
   });

   const fadeStyles2 = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration: 1000},
      delay: 1500
   });

   const fadeStyles3 = useSpring({
      from: {opacity: 0},
      to: {opacity: 1},
      config: {duration: 1000},
      delay: 2000
   });

   const createTransition = (active: boolean, delay: number) => useTransition(active, {
      from: {opacity: 0},
      enter: {opacity: 1},
      config: {duration: 1000},
      delay
   });

   const transitionTips = createTransition(activeSection === 1, 0);
   const transitionCard = createTransition(activeSection === 2, 250);
   const transitionTitle = createTransition(activeSection === 2, 750);
   const transitionImage = createTransition(activeSection === 2, 1250);
   const transitionText = createTransition(activeSection === 2, 1500);
   const transitionTeamoImage = useTransition(showTeamoForm, {
      from: {opacity: 0, transform: 'scale(0.90)'},
      enter: {opacity: 1, transform: 'scale(1)'},
      config: {duration: 350}
   });

   const [expanded, setExpanded] = useState(false);

   const transitions = useTransition(expanded, {
      from: {maxHeight: 100, opacity: 0},
      enter: {maxHeight: 300, opacity: 1},
      leave: {maxHeight: 100, opacity: 0},
      config: {tension: 170, friction: 26},
      duration: 1000
   });

   const authStore = useContext(AuthContext);
   const spinnerService = useLoadingSpinner();
   const {refreshToken} = useApiService();

   useEffect(() => {
      if (selectedSection) {
         setShowScoreResults(false);
         setOpenPanel(true);
      } else {
         if (!showScoreResults) {
            setOpenPanel(false);
         }
      }
      checkBorderRotationColoring();
   }, [selectedSection]);

   useEffect(() => {
      if (!openPanel) {
         setSelectedSection(undefined);
         setShowScoreResults(false);
      }
   }, [openPanel]);

   useEffect(() => {
      if (showScoreResults) {
         setSelectedSection(undefined);
         setOpenPanel(true);
      } else {
         if (!selectedSection) {
            setOpenPanel(false);
         }
      }
   }, [showScoreResults]);

   useEffect(() => {
      authStore.channelRegister.unbind(`augmentation_${authStore.userData.userId}`);
      authStore.channelRegister.bind(`augmentation_${authStore.userData.userId}`, (_data: { message: string }) => {
         initializeComponent(authStore.userData.accessToken);
      });
   }, []);

   useEffect(() => {
      authStore.changePageTitle("AI Impact Score");
      initializeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <>
         {
            augmentationData &&
            <div className={style["ai-impact-score-page"]}>
               {
                  augmentationData.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED ?
                     <>
                        {
                           activeSection === 0 &&
                           <div
                              className={`page-wrapper ${pageWidth.windowWidth <= BreakingPageWidths.mobile ? "mobile" : ""} not-active`}>
                              <div className="first-score-results">
                                 <div className="ai-score-wrapper">
                                    <h2 className="header-2 ai-title">Your AI Impact Score Results</h2>
                                    <div className="ai-body">
                                       <animated.div className="gauge-wrapper" style={fadeStyles}>
                                          <GaugeComponent
                                             style={{
                                                width: '100%',
                                                height: '100%'
                                             }} // Make the gauge fill its container
                                             arc={{
                                                cornerRadius: 1,
                                                width: 0.2,
                                                subArcs: [
                                                   {
                                                      limit: 15,
                                                      color: '#A2CEB4FF',
                                                      showTick: true
                                                   },
                                                   {
                                                      limit: 35,
                                                      color: '#F8D38FFF',
                                                      showTick: true
                                                   },
                                                   {
                                                      limit: 100,
                                                      color: '#F2AC9DFF',
                                                      showTick: true
                                                   },
                                                ],
                                             }}
                                             value={augmentationData ? augmentationData.aiRiskFactor : 0}
                                             labels={{
                                                valueLabel: {hide: true}
                                             }}
                                          />
                                          <div className="gauge-label">
                                             <h2
                                                className={"gauge-percentage header-2"}>{augmentationData ? augmentationData.aiRiskFactor + "%" : "0%"}</h2>
                                             <div className={"gauge-type"}>{getProgressColor()}</div>
                                          </div>
                                       </animated.div>
                                       <animated.p style={fadeStyles2}
                                                   className="gauge-text u-margin-top-s">{getAugmentationText()}</animated.p>
                                    </div>
                                 </div>
                                 <animated.p style={fadeStyles3}>
                                    <span className={'text-surface-30'}>{getAugmentationSubText()}</span>
                                 </animated.p>
                                 <animated.button style={fadeStyles3} className="button button-primary"
                                                  onClick={() => setActiveSection(1)}><span>Next</span><i
                                    className="fa-solid fa-arrow-right-long"></i></animated.button>
                              </div>
                           </div>
                        }
                        {
                           activeSection === 1 &&
                           <div
                              className={`page-wrapper ${pageWidth.windowWidth <= BreakingPageWidths.mobile ? "mobile" : ""} active`}>
                              <div className="ai-score-card">
                                 <div className="score-section">
                                    <h3 className="header-3">AI Impact Score</h3>
                                    <div className={"risk-factor"}>
                                       <div className="percentage">{augmentationData.aiRiskFactor}%</div>
                                       <LinearProgress className={getProgressColor()} variant="determinate"
                                                       value={augmentationData.aiRiskFactor}/>
                                       <div className="text-surface-30">{getProgressColor()}</div>
                                    </div>
                                    <i className="fa-solid fa-circle-info"
                                       onClick={() => setShowScoreResults(prevState => !prevState)}></i>
                                 </div>
                                 <button className="button button-black" onClick={() => setActiveSection(2)}>
                                    <i className="fa-solid fa-star"></i>
                                    <span>Tips and Recommendations</span>
                                 </button>
                              </div>

                              <section className="results-section">
                                 <div className="results-grid">
                                    <div
                                       className={`item item--1 liked-text ${checkActiveSectionText([ImpactScore.AUGMENTED_LIKED, ImpactScore.NOT_AUGMENTED_LIKED])}`}>
                                       <div className="text-wrapper">
                                          <i className="fa-solid fa-heart"></i>
                                          <span>liked</span>
                                       </div>
                                    </div>
                                    <div
                                       className={`item item--2 augmented-text ${checkActiveSectionText([ImpactScore.AUGMENTED_LIKED, ImpactScore.AUGMENTED_DISLIKED])}`}>
                                       <div className="text-wrapper reverse">
                                          <div className="robot-wrapper">
                                             <i className="fa-solid fa-robot"></i>
                                          </div>
                                          <span>augmented</span>
                                       </div>
                                    </div>
                                    <div
                                       className={`item item--3 ai-text ${checkActiveSection(ImpactScore.AUGMENTED_LIKED)}`}
                                       onClick={() => changeSection(ImpactScore.AUGMENTED_LIKED)}><span>AI for Your Favorite Tasks</span>
                                    </div>
                                    <div className="item item--4 teamo-image">
                                       <div className="image-wrapper">
                                          <CircularProgressbarWithChildren value={selectedSection ? 17.5 : 0}
                                                                           strokeWidth={5}
                                                                           styles={buildStyles({
                                                                              pathColor: '#A0A8E5',
                                                                              trailColor: '#2F303C',
                                                                              strokeLinecap: "butt",
                                                                              rotation: rotation.outer
                                                                           })}>
                                             <div style={{width: "80%"}}>
                                                <CircularProgressbarWithChildren value={selectedSection ? 25 : 0}
                                                                                 strokeWidth={3}
                                                                                 styles={buildStyles({
                                                                                    pathColor: '#A0A8E5',
                                                                                    trailColor: '#2F303C',
                                                                                    strokeLinecap: "butt",
                                                                                    rotation: rotation.inner
                                                                                 })}>
                                                   <RadialSeparators
                                                      count={4}
                                                      style={{
                                                         background: "#0E0C19FF",
                                                         width: "14px",
                                                         // This needs to be equal to props.strokeWidth
                                                         height: `${7}%`
                                                      }}
                                                   />
                                                   <div className={`teamo-wrapper ${getTeamoEyesPosition()}`}>
                                                      <img className={'teamo-image'}
                                                           src="/assets/images/ai-impact-score-icons/teamo-no-eyes-icon.svg"
                                                           alt="Teamo no eyes icon"/>
                                                      <img
                                                         src="/assets/images/ai-impact-score-icons/teamo-eyes-icon.svg"
                                                         alt="Teamo eyes icon" className="teamo-eyes-image"/>
                                                      <img
                                                         src="/assets/images/ai-impact-score-icons/teamo-blue-eye-socket-icon.svg"
                                                         alt="Teamo blue eye socket icon"
                                                         className="teamo-eye-socket-image"/>
                                                   </div>
                                                </CircularProgressbarWithChildren>
                                             </div>
                                          </CircularProgressbarWithChildren>
                                       </div>
                                    </div>
                                    <div
                                       className={`item item--5 ai-text ${checkActiveSection(ImpactScore.NOT_AUGMENTED_LIKED)}`}
                                       onClick={() => changeSection(ImpactScore.NOT_AUGMENTED_LIKED)}><span>Skills Untouched by AI</span>
                                    </div>
                                    <div
                                       className={`item item--6 augmented-text ${checkActiveSectionText([ImpactScore.NOT_AUGMENTED_LIKED, ImpactScore.NOT_AUGMENTED_DISLIKED])}`}>
                                       <div className="text-wrapper">
                                          <div className="robot-wrapper">
                                             <i className="fa-solid fa-slash"></i>
                                             <i className="fa-solid fa-robot"></i>
                                          </div>
                                          <span>Not augmented</span>
                                       </div>
                                    </div>
                                    <div
                                       className={`item item--7 ai-text ${checkActiveSection(ImpactScore.AUGMENTED_DISLIKED)}`}
                                       onClick={() => changeSection(ImpactScore.AUGMENTED_DISLIKED)}><span>AI for Your Tedious Tasks</span>
                                    </div>
                                    <div
                                       className={`item item--8 ai-text ${checkActiveSection(ImpactScore.NOT_AUGMENTED_DISLIKED)}`}
                                       onClick={() => changeSection(ImpactScore.NOT_AUGMENTED_DISLIKED)}><span>AI Can't Help Here (Yet)</span>
                                    </div>
                                    <div
                                       className={`item item--9 liked-text ${checkActiveSectionText([ImpactScore.AUGMENTED_DISLIKED, ImpactScore.NOT_AUGMENTED_DISLIKED])}`}>
                                       <div className="text-wrapper">
                                          <i className="fa-solid fa-heart-crack"></i>
                                          <span>disliked</span>
                                       </div>
                                    </div>
                                 </div>
                                 {
                                    transitionTips((style, item) =>
                                       item ? (
                                          <animated.div style={style} className="tips-section">
                                             <img src="/assets/images/ai-impact-score-icons/teamo-wand-icon.svg"
                                                  alt="Teamo wand"/>
                                             <img src="/assets/images/ai-impact-score-icons/cloud-tips-icon.svg"
                                                  alt="Cloud tips"/>
                                          </animated.div>
                                       ) : null
                                    )
                                 }
                              </section>

                              <div className={`teamo-form ${expanded ? "expanded" : ""}`}>
                                 <div className="header">
                                    <img src="/assets/images/ai-impact-score-icons/teamo-thinking-icon.svg" alt="Teamo thinking image"/>
                                    <h4 className="header-4">Let Us Know What You Think</h4>
                                    <i className="fa-solid fa-chevron-up" onClick={() => setExpanded(!expanded)}></i>
                                 </div>
                                 {transitions(
                                    (styles, item) =>
                                       item && (
                                          <animated.div style={styles} className="body">
                                             <p>Your answers will help us make TeamLift better. It'll only take 5 minutes to answer!</p>
                                             <button
                                                className="button button-white"
                                                onClick={() => {
                                                   window.open('https://l6qoyxngb2x.typeform.com/to/sFX6YGEo', '_blank');
                                                   setShowTeamoForm(false);
                                                }}>Tell us more about your experience
                                             </button>
                                          </animated.div>
                                       )
                                 )}
                              </div>
                           </div>
                        }
                        {
                           activeSection === 2 &&
                           <div
                              className={`page-wrapper ${pageWidth.windowWidth <= BreakingPageWidths.mobile ? "mobile" : ""} not-active`}>
                              <div className="subscribe-wrapper">
                                 {
                                    transitionCard((style, item) =>
                                       item ? (
                                          <animated.div style={style} className="subscribe-card">
                                             {
                                                transitionTitle((style, item) =>
                                                   item ? (
                                                      <animated.div style={style} className="title">
                                                         <i className="fa-solid fa-star"></i>
                                                         <h2 className="header-2">Tips and Recommendations</h2>
                                                         <i className="fa-solid fa-xmark"
                                                            onClick={() => setActiveSection(1)}></i>
                                                      </animated.div>
                                                   ) : null
                                                )
                                             }
                                             {
                                                transitionTitle((style, item) =>
                                                   item ? (
                                                      <animated.p style={style}
                                                                  className="text-surface-30">{getWhatToDoNextText()[0]}</animated.p>
                                                   ) : null
                                                )
                                             }
                                             {transitionImage((style, item) =>
                                                item ? (
                                                   <animated.div style={style}>
                                                      <img
                                                         src="/assets/images/ai-impact-score-icons/teamo-stars-icon.svg"
                                                         alt="Teamo stars"/>
                                                   </animated.div>
                                                ) : null
                                             )}
                                             {transitionText((style, item) =>
                                                item ? (
                                                   <animated.p style={style}>{getWhatToDoNextText()[1]}</animated.p>
                                                ) : null
                                             )}
                                             {
                                                !augmentationData.augmentationNotification &&
                                                transitionText((style, item) =>
                                                   item ? (
                                                      <animated.button style={style}
                                                                       className="button button-primary"
                                                                       onClick={() => subscribeToNews(authStore.userData.accessToken)}>Notify
                                                         Me</animated.button>
                                                   ) : null
                                                )
                                             }
                                          </animated.div>
                                       ) : null
                                    )
                                 }
                                 {
                                    augmentationData.augmentationNotification &&
                                    transitionText((style, item) =>
                                       item ? (
                                          <animated.div style={style} className="already-notified">
                                             <p>{getWhatToDoNextText()[2]}</p>
                                          </animated.div>
                                       ) : null
                                    )
                                 }
                              </div>
                           </div>
                        }
                     </>
                     : augmentationData.sessionStatus === QuestionnaireSessionStatusEnum.PROCESSING ?
                        <div className="pi-unfinished-main">
                           <div className="text-container">
                              <div className={"wrapper"}>
                                 <img src="/assets/images/ai-impact-score-icons/teamo-waiting-icon.svg"
                                      alt="Teamo waiting icon"/>
                                 <div>
                                    <h2 className="header-2">Just a moment, Teamo is analyzing your data.</h2>
                                    <div className="u-margin-top-m">
                                       <p>Our AI is analyzing your data.</p>
                                       <p>Your personalized insights will be ready in just a moment!</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> :
                        <div className="pi-unfinished-main">
                           <div className="text-container">
                              <div className={"wrapper"}>
                                 <img src="/assets/images/ai-impact-score-icons/teamo-die-icon.svg"
                                      alt="Teamo waiting icon"/>
                                 <div>
                                    <h2 className="header-2">Oops! There was an error.</h2>
                                    <div className="u-margin-top-m">
                                       <p>We're sorry, we ran into an issue.</p>
                                       <p>Please reach out to our support team at support@teamlift.co and we'll help you
                                          out.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
               }
            </div>
         }
         <ReactSlidingPane isOpen={openPanel}
                           onRequestClose={() => setOpenPanel(false)}
                           from={'right'}
                           className={`augmentation-panel`}
                           width='40%' hideHeader
                           overlayClassName={"augmentation__overlay"}>
            <div className="panel-content">
               <div className="panel-header">
                  {showScoreResults ? <h2 className="header-2">AI Impact Score Results</h2> : getImageAndTitle()}
                  <i className="fa-solid fa-xmark" onClick={() => setOpenPanel(false)}></i>
               </div>
               {
                  showScoreResults ?
                     <div className={"score-section"}>
                        <div className="gauge-wrapper">
                           <GaugeComponent
                              style={{width: '100%', height: '100%'}} // Make the gauge fill its container
                              arc={{
                                 cornerRadius: 1,
                                 width: 0.2,
                                 subArcs: [
                                    {
                                       limit: 15,
                                       color: '#A2CEB4FF',
                                       showTick: true
                                    },
                                    {
                                       limit: 35,
                                       color: '#F8D38FFF',
                                       showTick: true
                                    },
                                    {
                                       limit: 100,
                                       color: '#F2AC9DFF',
                                       showTick: true
                                    },
                                 ],
                              }}
                              value={augmentationData ? augmentationData.aiRiskFactor : 0}
                              labels={{
                                 valueLabel: {hide: true}
                              }}
                           />
                           <div className="gauge-label">
                              <h2
                                 className={"gauge-percentage header-2"}>{augmentationData ? augmentationData.aiRiskFactor + "%" : "0%"}</h2>
                              <div className={"gauge-type"}>{getProgressColor()}</div>
                           </div>
                        </div>

                        <p className="gauge-text">{getAugmentationText()}</p>

                        <button className="button button-black" onClick={() => {
                           setOpenPanel(false);
                           setActiveSection(2);
                        }}><i className="fa-solid fa-star"></i><span>Tips and Recommendations</span></button>
                     </div>
                     :
                     <>
                        <h3 className="header-3">{getPanelText()}</h3>
                        {getTeamoImageAndTitle()}
                        {getSkillsAndTools()}
                     </>
               }
            </div>
         </ReactSlidingPane>
         {
            spinnerService.spinner
         }
      </>
   );

   function initializeComponent(accessToken: string) {
      const getAugmentationReportURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/augmentation-report';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .post(getAugmentationReportURL, undefined, {headers})
         .then((response$: AxiosResponse<AugmentationReportDataRepresentationDto>) => {
            setAugmentationData(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function subscribeToNews(accessToken: string) {
      const subscribeToNewsURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/subscribe';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .post(subscribeToNewsURL, undefined, {headers})
         .then((response$: AxiosResponse<boolean>) => {
            setAugmentationData((prevState: any) => {
               return {
                  ...prevState,
                  augmentationNotification: response$.data
               }
            });
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     subscribeToNews(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getAugmentationText() {
      let title = '';
      let subtitle = '';
      if (augmentationData) {
         if (augmentationData.aiRiskFactor <= 15) {
            title = 'AI currently has little influence on what you do.';
            subtitle = 'You’re well-positioned to maintain your advantage with the right strategy.';
         } else if (augmentationData.aiRiskFactor <= 35) {
            title = 'AI\'s influence on your tasks is growing, but there\'s still time to get ahead!';
            subtitle = 'You’re in a great position to gain an advantage with the right strategy.';
         } else {
            title = 'AI\'s influence on your tasks is high.';
            subtitle = 'Don’t worry, you\'ve still got time and I’m ready to help.';
         }
      }
      return <><b>{title}</b><br/><span className="text-surface-30">{subtitle}</span></>;
   }

   function getAugmentationSubText() {
      if (augmentationData) {
         if (augmentationData.aiRiskFactor <= 15) {
            return 'First, we’ll take a closer look at your skills to determine which ones are most resilient to AI advancements, ensuring you continue to stay on track.';
         } else if (augmentationData.aiRiskFactor <= 35) {
            return 'First, we’ll take a closer look at the tools that can impact your skills. Then, we’ll share how you can use them to your advantage!';
         } else {
            return 'First, let\'s take a closer look at the specifics of your AI impact score. Then, we’ll guide you on managing this impact effectively.';
         }
      }
      return '';
   }

   function getWhatToDoNextText() {
      if (augmentationData) {
         if (augmentationData.aiRiskFactor <= 15) {
            return [
               'You’re ahead of the AI landscape at the moment! Keeping yourself there shouldn’t be too difficult, as long as you\'re aware of the latest AI advancements and tools!',
               'Since the AI landscape is always changing, it\'s important to stay updated on your AI impact score. If your score changes, would you like us to notify you?',
               'We’ll notify you when there’s been an update to your AI Impact Score.'
            ];
         } else if (augmentationData.aiRiskFactor <= 35) {
            return [
               'Make AI work for you, not against you! Incorporating AI tools into your workflow sooner rather than later can keep you ahead of your peers in this evolving landscape.',
               'We\'re collecting information on how other people like you are using these tools. Would you like us to notify you when it’s ready for sharing?',
               'We’ll notify you when the information is ready for sharing.'
            ];
         } else {
            return [
               'While AI can handle many tasks, there are certain areas where human abilities remain irreplaceable.',
               'We can identify skills and courses related to your strengths that are both in growing demand and at lower risk of AI influence! Would you like us to notify you when it\'s ready?',
               'We’ll notify you when the skills and courses are ready.'
            ]
         }
      }
      return [];
   }

   function getProgressColor() {
      if (augmentationData) {
         if (augmentationData.aiRiskFactor <= 15) {
            return 'low';
         } else if (augmentationData.aiRiskFactor <= 35) {
            return 'medium';
         } else {
            return 'high';
         }
      }
      return '';
   }

   function getTeamoEyesPosition() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return "bottom-right"
         case ImpactScore.AUGMENTED_DISLIKED:
            return "bottom-left"
         case ImpactScore.AUGMENTED_LIKED:
            return "top-left"
         case ImpactScore.NOT_AUGMENTED_LIKED:
            return "top-right"
         default:
            return "";
      }
   }

   function getSkillsAndTools() {

      const renderSkill = (skill: AugmentationSkillsRepresentationDto) => {
         return (
            <Tooltip key={`skill_${skill.skill.id}`} tooltipId={`skill_${skill.skill.id}`} place={"top"}
                     className={"custom-tooltip"} content={skill.skill.description}>
               <div className="skill">
                  <span>{capitalizeFirstLetter(skill.skill.name)}</span>
                  <i className="fa-regular fa-circle-question"></i></div>
            </Tooltip>
         );
      }

      const renderTools = (tools: SkillDto[]) => {
         return (
            tools.map(tool => {
               return (
                  <Tooltip key={tool.id} tooltipId={tool.id.toString()} className={"custom-tooltip"} place={"top"}
                           content={<div style={{fontFamily: "Inter, sans-serif"}}>{tool.description}</div>}>
                     <div className="tool" key={tool.id}>
                        <span>{tool.name}</span>
                        <i className="fa-regular fa-circle-question"></i>
                     </div>
                  </Tooltip>
               )
            })
         )
      }

      const renderSkillWithTools = (skill: AugmentationSkillsRepresentationDto) => {
         return (
            <div className="skill-tools-section" key={skill.skill.id}>
               <div className="skill-wrapper">{renderSkill(skill)}</div>
               <div className="tools-wrapper">
                  {skill.tools && renderTools(skill.tools)}
                  {
                     skill.counterTools && skill.counterTools > 0 &&
                     <div className="more-tools" key={skill.counterTools}>+{skill.counterTools} other
                        tool{skill.counterTools === 1 ? "" : "s"}</div>
                  }
               </div>
            </div>
         )
      }

      switch (selectedSection) {

         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return <div
               className="skills-section">{augmentationData && augmentationData.disLikeableNonAugmentedSkills.map(renderSkill)}</div>

         case ImpactScore.AUGMENTED_DISLIKED:
            return <div
               className="skills-tools-wrapper">{augmentationData && augmentationData.disLikeableAugmentedSkills.map(renderSkillWithTools)}</div>

         case ImpactScore.AUGMENTED_LIKED:
            return <div
               className="skills-tools-wrapper">{augmentationData && augmentationData.likeableAugmentedSkills.map(renderSkillWithTools)}</div>

         case ImpactScore.NOT_AUGMENTED_LIKED:
            return <div
               className="skills-section">{augmentationData && augmentationData.likeableNonAugmentedSkills.map(renderSkill)}</div>

         default:
            return <></>;
      }
   }

   function getPanelText() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return (
               <div className="panel-subtitle">
                  <span><i className="fa-solid fa-heart-crack"></i> Disliked,</span>
                  <span><span className="robot-wrapper">
                                       <i className="fa-solid fa-slash"></i>
                                       <i className="fa-solid fa-robot"></i>
                                    </span> Not Augmented with AI</span>
               </div>
            )
         case ImpactScore.AUGMENTED_DISLIKED:
            return (
               <div className="panel-subtitle">
                  <span><i className="fa-solid fa-heart-crack"></i> Disliked,</span>
                  <span><i className="fa-solid fa-robot"></i> Augmented with AI</span>
               </div>
            )
         case ImpactScore.AUGMENTED_LIKED:
            return (
               <div className="panel-subtitle">
                  <span><i className="fa-solid fa-heart"></i> Liked,</span>
                  <span><i className="fa-solid fa-robot"></i> Augmented with AI</span>
               </div>
            )
         case ImpactScore.NOT_AUGMENTED_LIKED:
            return (
               <div className="panel-subtitle">
                  <span><i className="fa-solid fa-heart"></i> Liked,</span>
                  <span><span className="robot-wrapper">
                                       <i className="fa-solid fa-slash"></i>
                                       <i className="fa-solid fa-robot"></i>
                                    </span> Not Augmented with AI</span>
               </div>
            )
         default:
            return "";
      }
   }

   function getImageAndTitle() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return (
               <div className={'title'}>
                  <img src="/assets/images/ai-impact-score-icons/tasks-icon.svg" alt="Tasks icon"/>
                  <h2 className="header-2">Your Energy Drainers</h2></div>)
         case ImpactScore.AUGMENTED_DISLIKED:
            return (
               <div className={'title'}>
                  <img src="/assets/images/ai-impact-score-icons/tasks-icon.svg" alt="Tasks icon"/>
                  <h2 className="header-2">Alleviate Tedious Tasks</h2></div>)
         case ImpactScore.AUGMENTED_LIKED:
            return (
               <div className={'title'}>
                  <img src="/assets/images/ai-impact-score-icons/wings-icon.svg" alt="Tasks icon"/>
                  <h2 className="header-2">AI-Amplified Passions</h2></div>)
         case ImpactScore.NOT_AUGMENTED_LIKED:
            return (
               <div className={'title'}>
                  <img src="/assets/images/ai-impact-score-icons/wings-icon.svg" alt="Tasks icon"/>
                  <h2 className="header-2">Your Human Superpowers</h2></div>)
         default:
            return "";
      }
   }

   function getTeamoImageAndTitle() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            return (
               augmentationData?.disLikeableNonAugmentedSkills && augmentationData.disLikeableNonAugmentedSkills.length > 0 ?
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-sad-icon.svg" alt="Teamo hands icon"/>
                     <p>These are your highlighted <span className="skill">skills</span> that we can't find <span className="tool">ai tools</span> for yet.</p>
                  </div> :
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                     <p>Congratulations! You’re ahead of the AI landscape at the moment. We can help keep you there by notifying you when new tools emerge that can affect your skills.</p>
                  </div>
            )
         case ImpactScore.AUGMENTED_DISLIKED:
            return (
               augmentationData?.disLikeableAugmentedSkills && augmentationData.disLikeableAugmentedSkills.length > 0 ?
               <div className={'info'}>
                  <img src="/assets/images/ai-impact-score-icons/teamo-hands-closed-eyes-icon.svg"
                       alt="Teamo hands icon"/>
                  <p>These are your disliked <span className="skill">skills</span> that can be augmented with <span className="tool">ai tools</span></p>
               </div> :
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                     <p>Congratulations! You’re ahead of the AI landscape at the moment. We can help keep you there by notifying you when new tools emerge that can affect your skills.</p>
                  </div>
            )
         case ImpactScore.AUGMENTED_LIKED:
            return (
               augmentationData?.likeableAugmentedSkills && augmentationData.likeableAugmentedSkills.length > 0 ?
               <div className={'info'}>
                  <img src="/assets/images/ai-impact-score-icons/teamo-small-hands-icon.svg" alt="Teamo hands icon"/>
                  <p>These are your <span className="skill">skills</span> that these <span className="tool">ai tools</span> might boost.
                  </p>
               </div> :
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                     <p>Congratulations! You’re ahead of the AI landscape at the moment. We can help keep you there by notifying you when new tools emerge that can affect your skills.</p>
                  </div>
            )
         case ImpactScore.NOT_AUGMENTED_LIKED:
            return (
               augmentationData?.likeableNonAugmentedSkills && augmentationData.likeableNonAugmentedSkills.length > 0 ?
               <div className={'info'}>
                  <img src="/assets/images/ai-impact-score-icons/teamo-hands-closed-eyes-icon.svg"
                       alt="Teamo hands icon"/>
                  <p>These are your liked <span className="skill">skills</span> that no AI tool can replace (for now)!
                  </p>
               </div> :
                  <div className={'info'}>
                     <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                     <p>Congratulations! You’re ahead of the AI landscape at the moment. We can help keep you there by notifying you when new tools emerge that can affect your skills.</p>
                  </div>
            )
         default:
            return "";
      }
   }

   function changeSection(section: ImpactScore) {
      if (selectedSection === section) {
         setSelectedSection(undefined);
      } else {
         setSelectedSection(section);
      }
   }

   function checkActiveSection(section: ImpactScore) {
      if (selectedSection) {
         if (selectedSection.valueOf() === section.valueOf()) {
            return "ai-selected";
         }
         return "ai-not-selected";
      }
      return "";
   }

   function checkActiveSectionText(sections: ImpactScore[]) {
      if (selectedSection && sections.includes(selectedSection)) {
         return "text-primary-20";
      }
      return "";
   }

   function checkBorderRotationColoring() {
      switch (selectedSection) {
         case ImpactScore.NOT_AUGMENTED_DISLIKED:
            setRotation({outer: .3, inner: .25})
            return;
         case ImpactScore.AUGMENTED_DISLIKED:
            setRotation({outer: .525, inner: .5})
            return;
         case ImpactScore.AUGMENTED_LIKED:
            setRotation({outer: .8, inner: .75});
            return;
         case ImpactScore.NOT_AUGMENTED_LIKED:
            setRotation({outer: 1.025, inner: 1})
            return;
         default:
            return;
      }
   }

}

export default AIImpactScorePage;