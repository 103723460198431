import { useEffect, useState } from 'react';

const useResponsiveComponent = () => {
   const [windowWidth, setWindowWidth] = useState(
      typeof window !== 'undefined' ? window.innerWidth : 0
   );

   let timeout: string | number | NodeJS.Timeout | undefined;

   useEffect(() => {


      const handleResize = () => {
         clearTimeout(timeout);

         timeout = setTimeout(() => {
            setWindowWidth(window.innerWidth);
         }, 200);
      }

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
   }, []);

   return { windowWidth };
};

export default useResponsiveComponent;