import React, {useContext, useEffect, useRef, useState} from "react";
import style from './SuperAdminPage.module.scss';
import useApiService from "../../../services/api.service";
import AuthContext from "../../../store/auth-context";
import useLoadingSpinner from "../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {
   EmployeeGridSuperAdminDto,
   PaginationDataDto,
   WaitingListDto,
   WaitingListPaginationDto,
   WaitingListRequestDto
} from "../../../types/SuperAdminData";
import {ErrorResponseDto} from "../../../types/ErrorData";
import {showNotification} from "../../../ui/Toast/ToastNotification";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import {getFormattedTime} from "../../../utils/get-time";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Pagination, PaginationItem} from "@mui/material";
import ReactSlidingPane from "react-sliding-pane";
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from "react-circular-progressbar";
import RadialSeparators1 from "../../../utils/image-separator";

const SuperAdminPage: React.FC = () => {

   const authStore = useContext(AuthContext);
   const spinnerService = useLoadingSpinner();
   const {refreshToken} = useApiService();

   const [employees, setEmployees] = useState<WaitingListDto[]>([]);
   const grid = useRef<any>();
   const gridRef = useRef<any>();
   const [pagination, setPagination] = useState<PaginationDataDto>({
      page: 1,
      pageSize: 50,
      totalPages: 0,
      totalCount: 0
   });
   const [searchTerm, setSearchTerm] = useState('');
   const [showPanel, setShowPanel] = useState(false);
   const [selectedEmployee, setSelectedEmployee] = useState<WaitingListDto>();

   useEffect(() => {
      authStore.changePageTitle("Super Admin Panel");
      initializeComponent(pagination.page, authStore.userData.accessToken);
   }, []);

   useEffect(() => {
      grid.current = new Tabulator(gridRef.current, {
         data: parseEmployees(employees),
         columns: gridColumnsDefinition,
         headerSortClickElement: "icon",
         layout: "fitColumns",
         validationMode: "highlight",
         headerSortElement: function (column, dir) {
            switch (dir) {
               case "asc":
                  return "<span class='material-symbols-outlined'>sort</span>";
               case "desc":
                  return "<span class='material-symbols-outlined'>sort</span>";
               default:
                  return "<span class='material-symbols-outlined'>sort</span>";
            }
         }
      });

      return () => {
         grid.current.destroy();
      }
   }, [employees]);

   const gridColumnsDefinition: any = [
      {title: "", field: "rowNumber", minWidth: 25, maxWidth: 75, formatter: "rownum", headerSort: false},
      {title: "Date added", field: "dateAdded", minWidth: 200, headerSortTristate: true},
      {title: "Email", field: "email", minWidth: 200, headerSortTristate: true, validator: ["required", "unique"]},
      {title: "Name", field: "name", minWidth: 200, headerSortTristate: true, validator: ["required"]},
      {title: "Status", field: "status", minWidth: 200, headerSortTristate: true},
      {
         title: "AI Impact Score", field: "aiImpactScore", minWidth: 200, headerSortTristate: true,

         formatter: (cell: any) => {
            let cellData: EmployeeGridSuperAdminDto = cell.getData();

            let wrapper = document.createElement('div');
            wrapper.classList.add('ai-score-cell');

            let score = document.createElement('span');
            score.innerText = cellData.aiImpactScore;

            let image = document.createElement('img');
            image.src = "/assets/images/ai-impact-score-icons/teamo-star-icon.svg";

            wrapper.appendChild(score);

            if (cellData.aiImpactScore !== 'N/A') {
               wrapper.appendChild(image);
            }

            return wrapper;

         }
      },
      {
         title: 'Actions', field: "actionButtons", headerSort: false, minWidth: 200, frozen: true,
         formatter: (cell: any) => {

            let cellData: EmployeeGridSuperAdminDto = cell.getData();

            // If registrationCode is null, there should be an action to send a registrationCode
            // If registrationCode is not null, but status is GENERATED_CODE, there is also an option to resend the reigstrationCode.

            let wrapper = document.createElement('div');
            wrapper.classList.add('row-actions');

            if (!cellData.status || (cellData.status && cellData.status === 'GENERATED CODE')) {
               // SEND CODE BUTTON
               let button = document.createElement('button');
               button.classList.add('button', 'button-tertiary');
               button.innerText = !cellData.status ? 'Send registration code' : 'Resend registration code';
               button.title = 'Send verification code';
               button.addEventListener('click', () => {
                  sendCode(cellData, authStore.userData.accessToken);
               });

               wrapper.appendChild(button);
            }

            const selectedEmployee = employees.find(employee => employee.email === cellData.email);

            if (selectedEmployee && selectedEmployee.augmentationInformation) {
               // SIDE PANEL BUTTON
               let button = document.createElement('button');
               button.classList.add('button', 'button-tertiary');
               button.innerText = 'See more information';
               button.title = 'See more augmented information';
               button.addEventListener('click', () => {
                  setShowPanel(true);
                  setSelectedEmployee(selectedEmployee);
               });

               wrapper.appendChild(button);
            }

            return wrapper;
         }
      }
   ];

   return (
      <>
         <div className={style['super-admin-page']}>
            <div className="tabs-container">
               <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
                  <TabList>
                     <Tab>Registered users</Tab>
                  </TabList>
                  <TabPanel>
                     <div>
                        <div className='filter-element'>
                           <div className='input-wrapper'>
                              <label htmlFor='grid-filter-input' className='search-icon-wrapper'>
                                 <i className="fa-solid fa-magnifying-glass"></i>
                              </label>
                              <input type='text' placeholder='Search' value={searchTerm}
                                     onChange={e => setSearchTerm(e.target.value)} autoComplete='off'/>
                              <span className='clear-icon-wrapper' title='Clear filter' onClick={() => resetFilter()}><i
                                 className="fa-solid fa-xmark"></i></span>
                           </div>
                           <button className="button button-primary"
                                   onClick={() => initializeComponent(1, authStore.userData.accessToken)}>Search
                           </button>
                        </div>
                     </div>
                     <div className='grid-wrapper'>
                        <div id='admin-grid' ref={gridRef}></div>
                     </div>
                     <div className="pagination-row">
                        <div className="categories">
                           <div className="categories-title">Employees per page:</div>
                           <div className="categories-number">{employees.length}</div>
                        </div>
                        <Pagination defaultPage={1} page={pagination.page}
                                    count={pagination.totalPages} variant="outlined" shape="rounded"
                                    renderItem={(item) => <PaginationItem {...item} />} onChange={clickedOnPage}/>
                     </div>
                  </TabPanel>
               </Tabs>
            </div>
         </div>
         <ReactSlidingPane onRequestClose={() => setShowPanel(false)} width={'45%'} hideHeader from={'right'}
                           isOpen={showPanel} className={"super-admin-side-panel"}>
            <div className={"panel-content"}>
               {
                  selectedEmployee &&
                  <>
                     <div className="panel-header">
                        <h2 className="header-2">{selectedEmployee.name}'s data</h2>
                        <div onClick={() => setShowPanel(false)} className="close-button-container"><i
                           className="fa-solid fa-xmark"></i></div>
                     </div>

                     <p className="text-surface-30 u-margin-top-xxs u-margin-bottom-xxs">Employee information about
                        categories, ai risk factor and questions with answers.</p>

                     {
                        selectedEmployee.augmentationInformation.categories.length > 0 &&
                        <div className="categories">
                           <h3 className="header-3">Selected categories</h3>
                           <div className="categories-wrapper u-margin-top-xs">
                              {
                                 selectedEmployee.augmentationInformation.categories.map(category => {
                                    return (
                                       <div className="category-item">
                                          <i className="fa-solid fa-check"></i>
                                          <span>{category}</span>
                                       </div>
                                    )
                                 })
                              }
                           </div>
                        </div>
                     }

                     <div className="session-status">
                        <h3 className="header-3">Session
                           status: {selectedEmployee.augmentationInformation.sessionStatus}</h3>
                     </div>

                     {
                        selectedEmployee.augmentationInformation.report === null ?
                           <div className={'ai-risk'}>
                              <h3 className="header-3 u-margin-bottom-s">AI Impact Score</h3>
                              <div className="not-prepared-title">
                                 <i className="fa-solid fa-xmark"></i>
                                 <h4 className="header-4">The report hasn't been prepared yet.</h4>
                              </div>
                           </div> :
                           <div className="ai-risk">
                              <h3 className="header-3 u-margin-bottom-l">AI Impact Score</h3>
                              <div className="chart-row">
                                 <div className="pie-wrapper">
                                    <CircularProgressbarWithChildren
                                       value={selectedEmployee.augmentationInformation.report ? selectedEmployee.augmentationInformation.report.aiRiskFactor : 0}
                                       strokeWidth={3}
                                       styles={buildStyles({
                                          pathColor: '#E8EAF9',
                                          trailColor: 'transparent',
                                          strokeLinecap: "butt"
                                       })}>
                                       <RadialSeparators1
                                          count={0}
                                          style={{
                                             background: "transparent",
                                             width: "12px",
                                             height: `${7}%`
                                          }}
                                          progress={selectedEmployee.augmentationInformation.report ? selectedEmployee.augmentationInformation.report.aiRiskFactor : 0}
                                          imageSrc={'/assets/images/ai-impact-score-icons/teamo-star-icon.svg'}/>
                                       <div style={{width: "95%"}}>
                                          <CircularProgressbar
                                             value={selectedEmployee.augmentationInformation.report ? selectedEmployee.augmentationInformation.report.aiRiskFactor : 0}
                                             strokeWidth={50}
                                             styles={buildStyles({
                                                pathColor: '#A0A8E5',
                                                trailColor: '#E8EAF9',
                                                strokeLinecap: "butt"
                                             })}/>
                                       </div>
                                    </CircularProgressbarWithChildren>
                                    <div
                                       className="percent">{selectedEmployee.augmentationInformation.report ? (selectedEmployee.augmentationInformation.report.aiRiskFactor ? selectedEmployee.augmentationInformation.report.aiRiskFactor : 0) : 0}%
                                    </div>
                                 </div>
                                 <div className="info">
                                    <img src="/assets/images/ai-impact-score-icons/teamo-small-hands-icon.svg"
                                         alt="teamo icon"/>
                                    <p>This employee AI impact score.</p>
                                 </div>
                              </div>
                           </div>
                     }

                     <div className="questions-answers">
                        <h3 className="header-3 u-margin-bottom-s">Questions and Answers</h3>
                        <div className="qa-wrapper">
                           {
                              selectedEmployee.augmentationInformation.answers.map(q => {
                                 return (
                                    <div className="qa-section">
                                       <div className="question">{q.question}</div>
                                       <div className="answer">{q.answer}</div>
                                    </div>
                                 )
                              })
                           }
                        </div>
                     </div>

                     <div className="subscribed-status">
                        <h3 className="header-3">Subscribed
                           status: {selectedEmployee.augmentationInformation.subscribed ? "Subscribed" : "Not subscribed"}</h3>
                     </div>

                  </>
               }
            </div>
         </ReactSlidingPane>
         {
            spinnerService.spinner
         }
      </>
   );

   function resetFilter() {
      setSearchTerm('');
      initializeComponent(1, authStore.userData.accessToken, true);
   }

   function clickedOnPage(_event: React.ChangeEvent<unknown>, page: number) {
      initializeComponent(page, authStore.userData.accessToken);
   }

   function parseEmployees(employees: WaitingListDto[]) {
      return employees.map(employee => {
         const registrationCode = employee.registrationCode ? {
            status: employee.registrationCode.status?.replace("_", " "),
         } : {};
         return {
            email: employee.email,
            name: employee.name,
            dateAdded: getFormattedTime(employee.dateAdded.toString()),
            aiImpactScore: employee.aiRiskFactor ? employee.aiRiskFactor + "%" : "N/A",
            ...registrationCode,
         }
      });
   }

   function sendCode(cellData: EmployeeGridSuperAdminDto, accessToken: string) {
      const sendVerificationCode = process.env.REACT_APP_PUBLIC_URL + "/super-admin/generate-registration-code";

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         email: cellData.email,
      }

      axios
         .post(sendVerificationCode, data, {headers})
         .then((response$: AxiosResponse<WaitingListDto>) => {
            setEmployees(prevState => prevState.map(employee => employee.id === response$.data.id ? response$.data : employee));
            showNotification("success", `Successfully send registration code to user ${cellData.email}`);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sendCode(cellData, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function initializeComponent(page: number, accessToken: string, paginationSearchTerm?: boolean) {
      const superAdminURL = process.env.REACT_APP_PUBLIC_URL + "/super-admin/super-admin-panel";

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data: WaitingListRequestDto = {
         page: page,
         pageSize: 50,
         searchTerm: paginationSearchTerm ? undefined : searchTerm.trim().length === 0 ? undefined : searchTerm
      }

      axios
         .post(superAdminURL, data, {headers})
         .then((response$: AxiosResponse<WaitingListPaginationDto>) => {
            setEmployees(response$.data.waitingListData);
            setPagination(response$.data.pagination);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(page, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })

   }

}

export default SuperAdminPage;