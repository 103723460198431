import React from "react";
import style from './RegisterEmailPage.module.scss';
import axios, {AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../types/ErrorData";
import {adminRegexPatterns} from "../../types/AdminData";
import SpinnerButton from "../../ui/SpinnerButton/SpinnerButton";
import useResponsiveComponent from "../../ui/ResponsiveComponent/ResponsiveComponent";
import {BreakingPageWidths} from "../../types/constants/BreakingWidths";
import {useNavigate} from "react-router-dom";

const RegisterEmailPage: React.FC = () => {

   const [email, setEmail] = React.useState('');
   const [showError, setShowError] = React.useState<string>();
   const [showMessage, setShowMessage] = React.useState(false);
   const [dataLoading, setDataLoading] = React.useState(false);
   const width = useResponsiveComponent();
   const navigate = useNavigate();

   return (
      <>
         <div className={style['email-register-page']}>

            <div className="page-wrapper">

               <section className="form-section">
                  <img src="/assets/images/global-icons/teamlift-logo-white.svg" alt="Teamlift logo"/>
                  {
                     showMessage ?
                        <div className={"end-message"}>
                           <h3 className={'header-3 u-margin-bottom-s'}>Thank you for joining!</h3>
                           <p>You should've received a welcome email containing detailed instructions for onboarding to TeamLift.</p>
                           <p className={'u-margin-top-xxs'}>If you haven’t received an email, please let us know at support@teamlift.co.</p>
                        </div>
                        :
                        <>
                           <div className="email-form">
                              <h1 className="header-1">Welcome!</h1>
                              <p className="text-surface-30">Enter your email below to get your personalized AI Impact
                                 Report.</p>
                              <input type="text" value={email} placeholder={"your@email.com"} onKeyDown={e => e.key === 'Enter' ? sendEmail() : () => {}}
                                     onChange={e => setEmail(e.target.value)} />
                              {
                                 showError && <div className="error-message"><i
                                    className="fa-solid fa-triangle-exclamation"></i> {showError}</div>
                              }
                              <SpinnerButton title={'Join'} cssClasses="button button-primary"
                                             onClickCallback={sendEmail} dataLoading={dataLoading}/>
                              <div className="already-account"><span>Already have an account?</span> <button className="button button-tertiary" onClick={() => navigate("/login")}>Log in</button></div>
                           </div>
                        </>
                  }
               </section>

               {
                  width.windowWidth > BreakingPageWidths.mobile &&
                  <section className="teamo-guide-section">
                     <img src="/assets/images/ai-impact-score-icons/teamo-ai-guide-icon.svg" alt="Teamo guide icon"/>
                  </section>
               }
            </div>
         </div>
      </>
   )

   function sendEmail() {
      const sendEmailURL = process.env.REACT_APP_PUBLIC_URL + '/auth/website/subscribe-user';

      if (!adminRegexPatterns.email.test(email)) {
         setShowError('Invalid email format');
         return;
      }

      const data = {
         email
      }

      setDataLoading(true);

      axios
         .post(sendEmailURL, data)
         .then((response$: AxiosResponse<boolean>) => {
            setDataLoading(false);
            setShowMessage(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            setDataLoading(false);
            setShowError(error$.response.data.message);
         })

   }

}

export default RegisterEmailPage;