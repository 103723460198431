import React, { useState, useContext } from 'react';
import AuthContext from '../../../../../../store/auth-context';
import Select, {MenuPlacement} from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import Tooltip from '../../../../../../services/tooltip.service';
import axios, {AxiosRequestConfig} from 'axios';
import useApiService from '../../../../../../services/api.service';
import {capitalizeFirstLetter} from "../../../../../../utils/capitalize-first-letter";
import {showNotification} from "../../../../../../ui/Toast/ToastNotification";
import {SkillsPaginationDto} from "../../../../../../types/ProjectsStoreTypes";

const CreatableAsyncPaginate = withAsyncPaginate(
  Select
);

const selectStyles = {
  container: (provided: {}) => ({
    ...provided,
    'flex': '1',
  }),
  control: (provided: {}) => ({
    ...provided,
    'backgroundColor': '#21262E',
    'border': '1px solid rgba(79, 89, 105, 0.45)',
    'paddingLeft': '16px',
    'paddingTop': '12px',
    'paddingRight': '16px',
    'paddingBottom': '12px',
    'borderRadius': '4px'
  }),
  indicatorSeparator: (provided: {}) => ({
    'display': 'none'
  }),
  dropdownIndicator: (provided: {}) => ({
    'display': 'none'
  }),
  clearIndicator: (provided: {}) => ({
    'display': 'none'
  }),
  placeholder: (provided: {}) => ({
    ...provided,
    'color': 'rgba(201, 209, 217, 0.45)',
    'fontSize': '15px'
  }),
  loadingMessage: (provided: {}) => ({
    ...provided,
    'color': 'rgba(201, 209, 217, 0.45)'
  }),
  loadingIndicator: (provided: {}) => ({
    ...provided,
    'color': 'rgba(201, 209, 217, 0.45)',
  }),
  input: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8'
  }),
  noOptionsMessage: (provided: {}) => ({
    ...provided,
    'color': '#757575'
  }),
  menu: (provided: {}) => ({
    ...provided,
    'backgroundColor': '#191D24'
  }),
  singleValue: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8'
  }),
  option: (provided: {}) => ({
    ...provided,
    'color': '#F7F7F8'
  })
}

const SelectComponent: React.FC<{skillsToBeAdded: any, addSkillFromSelect: Function, menuPlacement?: MenuPlacement}> = (props) => {
  const authStore = useContext(AuthContext);
  const [selectValue, setSelectValue] = useState(''); 
  const [loadingMessage, setLoadingMessage] = useState<string>("Loading...");

  const apiService = useApiService();

  return (
    <CreatableAsyncPaginate
      menuPlacement={props.menuPlacement ? props.menuPlacement : "bottom"}
      className="basic-single"
      classNamePrefix="select"
      isClearable={true}
      isSearchable={true}
      name="skill"
      formatOptionLabel={formatOptionLabel} 
      styles={selectStyles}
      value={selectValue}
      backspaceRemovesValue={true}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#2D2F38',
          primary: '#757575',
        },
      })} 
      placeholder="Front end, Data science, Agile methodology, etc."
      loadingMessage={() => loadingMessage}              
      noOptionsMessage={
        (value) => value.inputValue === "" ? "Start typing" : "No options"
      }               
      debounceTimeout={1200}
      loadOptionsOnMenuOpen={false}
      additional={{page: 1}}
      loadOptions={(search: string, options: any, aditional: any)=> loadOptions(search, options, aditional, authStore.userData.accessToken)}
      onChange={(value: any) => {
        if (value) {
          selectSkill(value); 
        }                          
      }}
      onInputChange={(value)=> {
        if (value === '') {                  
          setLoadingMessage('Loading...');       
          setSelectValue('');
        }
        else if (value.trim().length < 2) {
          setLoadingMessage('Please enter a minimum of two characters to view the searched skills');
        }
        else {
          setLoadingMessage('Loading...');
        }
      }}
      onBlur={()=> {
        setLoadingMessage('Loading...');
        setSelectValue('');
      }}
      onMenuClose={()=> { 
        setLoadingMessage('Loading...');
        setSelectValue('');
      }}
    />
  )

  async function loadOptions(search: string, loadedOptions: unknown[], additional: any, accessToken: string): Promise<any> {
    let options: any = [];
    let additionalObject: any = {};
    let returningObject: any = {
      options: options
    }
    
    try {
      if (search.trim().length > 1) {
        // setInputValue(search.trim());

        let result: SkillsPaginationDto = await searchSkill(search.trim(), additional.page, null, accessToken) as SkillsPaginationDto;

        if (result.searchedSkills.length > 0) {
          for (let item of result.searchedSkills) {
            options.push({ value: item.aiId, label: capitalizeFirstLetter(item.name), description: item.description })
          }
  
          returningObject.hasMore = result.totalCount > result.page * result.pageSize ? true : false;         
          additionalObject.page = additional.page + 1;
          returningObject.additional = additionalObject;    
        }
      }
    }
    catch(error$: any) { // It has to be any because of typescript error
      if (error$.response.data.message === 'Unauthorized') {
        // Get new Access Token
        const response$: any = await apiService.refreshToken(authStore.userData.refreshToken);
        authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
        // Retry the loadOptions function with the new access token
        return await loadOptions(search, loadedOptions, additional, response$.data.accessToken);
      }
      else {
        showNotification('warning', error$.response.data.message);
      }
    }    
    return returningObject;
  }

  function selectSkill(newValue: any) {
    let skillId: number = newValue.value;
    let skill: any = props.skillsToBeAdded.find((item: any) => item.value === skillId);

    if (!skill) {
      props.addSkillFromSelect(newValue);
    }
  }

  function formatOptionLabel (data: any) {
    return <div style={{ display: "block" }}>
      <span>{data.label}</span>
      {
        data.description &&
        <span className={"u-margin-left-xxs"}>
          <Tooltip
            place="right"                                         
            tooltipId={data.value.toString()}
            backgroundColor="#101319"
            borderColor='#5C5F6A !important' 
            border={true}            
            content={
              <span>{data.description}</span>                                 
            }
            className='tag-skill-description'
          >
            <span className='select-skill-description-symbol'><i className="fa-regular fa-circle-question"></i></span>
          </Tooltip>  
        </span>
      }
    </div>
  }

  async function searchSkill(searchTerm: string, page: number, projectId: any, accessToken: string) {
    const searchSkillURL: string = process.env.REACT_APP_PUBLIC_URL + '/company/search-taxonomy-skills';
    const paginationSize: number = 100;

    const headers: AxiosRequestConfig['headers'] = {
      'Authorization': `Bearer ${accessToken}`
    }
    let params: {} = {
      projectId: projectId,
      searchTerm: searchTerm,
      page: page,
      pageSize: paginationSize

    }
    let promise = new Promise((resolve: any, reject: any) => {
      axios
        .post(searchSkillURL, params,{ headers })
        .then((response$: {data: SkillsPaginationDto}) => {
          resolve(response$.data);
        })
        .catch((error$) => {
          // REFRESH TOKEN - DONE
          reject(error$);
        });
    });
    return await promise;
  }
}

export default SelectComponent;