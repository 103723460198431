import {SynonymDto} from "./Settings";
import {LevelDto} from "./Projects";

export type AugmentationReportDataRepresentationDto = {
   likeableAugmentedSkills: AugmentationSkillsRepresentationDto[];
   likeableNonAugmentedSkills: AugmentationSkillsRepresentationDto[];
   disLikeableAugmentedSkills: AugmentationSkillsRepresentationDto[];
   disLikeableNonAugmentedSkills: AugmentationSkillsRepresentationDto[];
   augmentedSkillsPercent: number;
   likeableAugmentedSkillsPercent: number;
   disLikeableAugmentedSkillsPercent: number;
   nonAugmentedSkillsPercent: number;
   likeableNonAugmentedSkillsPercent: number;
   disLikeableNonAugmentedSkillsPercent: number;
   aiRiskFactor: number;
   sessionStatus: QuestionnaireSessionStatusEnum;
   augmentationNotification?: boolean;
}

export type AugmentationSkillsRepresentationDto = {
   skill: SkillDto;
   tools?: SkillDto[];
   counterTools?: number;
}

export type SkillDto = {
   id: number;
   name: string;
   description: string;
   icon: string;
   category: string;
   aiId: string;
   isTool: boolean;
   isAITool: boolean;
   isGeneralSoftSkill: boolean;
   level: LevelDto;
   synonym?: SynonymDto;
   status?: number;
}

export enum QuestionnaireSessionStatusEnum {
   OPEN = 'OPEN',
   PROCESSING = 'PROCESSING',
   FINISHED = 'FINISHED',
   FAILED = 'FAILED',
   BE_FAILED = 'BE_FAILED',
}