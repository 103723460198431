import style from './../EmployeeOnboardPage/EmployeeOnboardPage.module.scss';
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../../store/auth-context";
import useApiService from "../../../../services/api.service";
import {useNavigate} from "react-router-dom";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import {RegisterQuestionDto} from "../../../../types/EmployeeOnboardRegisterData";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import IntroductionPage from "../EmployeeOnboardPage/IntroductionPage/IntroductionPage";
import EmployeeQuestionPage from "./EmployeeQuestionPage/EmployeeQuestionPage";
import EmployeeFinishPage from "./EmployeeFinishPage/EmployeeFinishPage";

const EmployeeOnboardRegisterPage = () => {

   const [employeeQuestions, setEmployeeQuestions] = useState<RegisterQuestionDto[]>([]);
   const [employeeSection, setEmployeeSection] = React.useState(-1); // -1 for introduction page
   const [dataLoading, setDataLoading] = useState(false);

   const authStore = useContext(AuthContext);
   const navigate = useNavigate();
   const spinnerService = useLoadingSpinner();
   const { refreshToken } = useApiService();

   useEffect(() => {
      getEmployeeQuestions(authStore.userData.accessToken);
   }, []);

   return (
      <>
         <div className={style['employee-onboard-page']}>

            <div className="employee-onboard-header">
               <img src="/assets/images/global-icons/teamlift-logo-white.svg" alt="TeamLift logo"/>
               <button className="button button-tertiary" onClick={authStore.logout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Log out</button>
            </div>

            <div className="onboard-container">
               {
                  employeeSection !== -1 &&
                  <div className="progress-bar-onboard">
                     {
                        employeeQuestions.map((q, index) => <div className={index <= employeeSection ? "active" : ""} key={q.id}></div>)
                     }
                  </div>
               }
               <main>
                  {
                     employeeSection === -1 &&
                     <IntroductionPage  changeSection={() => setEmployeeSection(0)} />
                  }
                  {
                     employeeSection > -1 && employeeSection < employeeQuestions.length &&
                     <EmployeeQuestionPage question={employeeQuestions[employeeSection]} saveAnswer={saveAnswer} goBack={employeeSection > 0 ? goBack : undefined} dataLoading={dataLoading} />
                  }
                  {
                     employeeSection === employeeQuestions.length &&
                     <EmployeeFinishPage goBack={goBack} finish={() => finishQuestionnaire(authStore.userData.accessToken)} dataLoading={dataLoading} />
                  }
               </main>
            </div>
         </div>
         {
            spinnerService.spinner
         }
      </>
   );

   function goBack() {
      setEmployeeSection(prevState => prevState - 1);
   }

   function finishQuestionnaire(accessToken: string) {
      const finishQuestionnaireURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/finish-questionnaire';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      setDataLoading(true);

      axios
         .post(finishQuestionnaireURL, undefined, {headers: headers})
         .then((_response$: AxiosResponse<boolean>) => {
            authStore.changeOnboarding();
            setDataLoading(false);
            navigate("/augmentation/augmentation-report");
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     finishQuestionnaire(response$.data.accessToken);
                  })
            } else {
               setDataLoading(false);
               showNotification('warning', error$.response.data.message);
            }
         })
   }


   function saveAnswer(questionId: number, answerText: string, accessToken?: string) {

      if (employeeQuestions.find(q => q.id === questionId)?.answer?.trim().toLowerCase() === answerText.trim().toLowerCase()) {
         setEmployeeSection(prevState => prevState + 1);
         return;
      }

      const saveAnswerURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/save-answer';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken ? accessToken : authStore.userData.accessToken}`
      }

      const data = {
         questionId,
         answerText
      }

     setDataLoading(true);

      axios
         .post(saveAnswerURL, data, {headers: headers})
         .then((response$: AxiosResponse<RegisterQuestionDto>) => {
            setEmployeeQuestions(prevState => prevState.map(q => q.id === response$.data.id ? response$.data : q));
            setEmployeeSection(prevState => prevState + 1);
            setDataLoading(false);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     saveAnswer(questionId, answerText, response$.data.accessToken);
                  })
            } else {
               setDataLoading(false);
               showNotification('warning', error$.response.data.message);
            }
         })

   }

   function getEmployeeQuestions(accessToken: string) {
      if (!authStore.userData.isOnboarding) {
         navigate("/augmentation/augmentation-report");
      }
      const getEmployeeComponentURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/questions';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      spinnerService.createSpinner();

      axios
         .get(getEmployeeComponentURL, {headers})
         .then((response$: AxiosResponse<RegisterQuestionDto[]>) => {
            setEmployeeQuestions(response$.data);
            const index = response$.data.slice().reverse().findIndex(q => q.isAlreadyAnswered);
            if (index > -1) {
               setEmployeeSection(response$.data.length - index);
            }
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getEmployeeQuestions(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }
}

export default EmployeeOnboardRegisterPage;